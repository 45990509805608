import { createContext, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export interface DragReorderContextValue {
  canDrag?: boolean
  setCanDrag?: Function
  items: any[]
  setDragItems: Function
  isDraggable: boolean
  setIsDraggable: Function
  isReordering: boolean
  movedItemId?: string
  setMovedItemId: Function
  onReorder?: Function
  setApiReorderCallback: Function
}
export const DragReorderContext = createContext(undefined)

const draggableRoutes = [
  '/settings/queues',
  '/settings/custom-fields',
  '/settings/vwr',
]

export function DragReorderContextProvider({ children }) {
  const { pathname } = useRouter()
  const [items, setItems] = useState([])
  const [isDraggable, setIsDraggable] = useState(false)
  const [isReordering, setIsReordering] = useState(false)
  const [movedItemId, setMovedItemId] = useState(null)
  const [callback, setCallback] = useState(null)
  // canDrag is used on mobile to enforce long press
  const [canDrag, setCanDrag] = useState(false)

  useEffect(() => {
    if (!draggableRoutes.includes(pathname)) {
      setIsDraggable(false)
    }
  }, [pathname])

  const setApiReorderCallback = (callback: Function) =>
    setCallback(() => callback)

  const onReorder = useCallback(
    async (data) => {
      if (callback && isDraggable) {
        setIsReordering(true)
        await callback(movedItemId, items)
        setIsReordering(false)
      }
    },
    [movedItemId, callback, items]
  )

  const publicData: DragReorderContextValue = {
    canDrag,
    setCanDrag,
    items,
    setDragItems: setItems,
    isDraggable,
    setIsDraggable,
    isReordering,
    onReorder,
    movedItemId,
    setMovedItemId,
    setApiReorderCallback,
  }

  return (
    <DragReorderContext.Provider value={publicData}>
      {children}
    </DragReorderContext.Provider>
  )
}
